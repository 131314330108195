<template>
    <v-row>
        <v-col cols="12" md="12" sm="12" class="small-graph-col">
            <div>
                <v-card :class="isCounterRunning ? 'running-small-graph-card' : 'small-graph-card'" variant="outlined">
                    <div v-if="isCounterRunning">
                        <img src="go.gif" class="gif-image" />
                    </div>
                    <div v-else>
                        <img src="wait.gif" class="gif-image" />
                    </div>
                    <div class="betting-text mt-n3">
                        <h4 v-if="isCounterRunning" class="green-text">
                            <span class="small-multi-text">{{ counter }} <span class="mt-n3">x</span></span>
                        </h4>

                        <h4 v-else-if="crashPoint !== 0" class="text-red">
                            <span class="small-multi-text">{{ crashPoint }} <span class="mt-n3"></span></span>
                        </h4>

                        <h4 v-else>
                            <span class="small-multi-text">{{ defcounter }}.00 <span class="mt-n6">x</span></span>
                        </h4>
                    </div>
                </v-card>
            </div>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="small-bet-col mt-0">
            <v-row>
                <!-- adding bet buttons -->
                <v-col cols="5">
                    <v-btn class="text-none add-bet-btn pa-1 mx-auto mt-3 mx-auto" size="small" rounded flat plain
                        variant="outlined">
                        <v-icon color="white" class="mt-n1 ml-6"
                            @click="reduceBetAmt()">mdi-minus-circle-outline</v-icon>
                        <span class="amount-bet">
                            <input ref="numberInput" v-on:input="validateInput" class="new-outline mt-n2"
                                v-model="defAmt" type="number" min="0" /></span>
                        <v-icon color="white" class="mt-n1 mr-6" @click="addBetAmt()">mdi-plus-circle-outline
                        </v-icon>
                    </v-btn>

                    <!-- amount buttons -->
                    <v-row>
                        <v-col cols="6">
                            <v-btn class="text-none chip-bet-btn mt-2 ml-2" size="x-small" rounded flat plain
                                @click="addOneDefAmt()" variant="outlined">
                                <span class="bet-btn-txt">100</span>
                            </v-btn>
                            <v-btn class="text-none chip-bet-btn mt-2 ml-2" size="x-small" rounded
                                @click="addTwoDefAmt()" flat plain variant="outlined">
                                <span class="bet-btn-txt">200</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn class="text-none chip-bet-btn mt-2 ml-2" size="x-small" rounded flat
                                @click="addFiveDefAmt()" plain variant="outlined">
                                <span class="bet-btn-txt">500</span>
                            </v-btn>
                            <v-btn class="text-none chip-bet-btn mt-2 ml-2" size="x-small" rounded flat
                                @click="addOneThDefAmt()" plain variant="outlined">
                                <span class="bet-btn-txt">1000</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="7" class="mx-auto mt-0">
                    <v-btn v-if="!playing && isLoggedIn && !pressButton" height="70" width="400"
                        class="green-card mt-4" @click="checkMyBets ? handleButtonClick() : cantBet()">
                        BET<br />
                        {{ defAmt }}.00 KES</v-btn>
                    <v-btn v-if="!isLoggedIn" :to="{ name: 'signIn' }" height="70" width="400" class="orange-card mt-4">
                        BET<br />
                        {{ defAmt }}.00 KES</v-btn>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-1">
                <span class="text-white mt-3"> CashOut</span>
            </v-row>

            <v-row justify="center" class="mb-4">
                <div class="input-container">
                    <input v-on:input="validateBetInput" class="red-outline mx-3 ml-5" v-model="bet_mlt" type="text" />
                    <span class="clear-button">x</span>
                </div>
            </v-row>
            <!-- </v-card> -->
        </v-col>
    </v-row>


    <v-snackbar v-model="errorsnackbar" class="mt-n12">
        {{ text }}

        <template v-slot:actions>
            <v-btn color="pink" variant="text" @click="errorsnackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>

    <v-snackbar v-model="bettingsnackbar" class="mt-n12">
        {{ betText }}

        <template v-slot:actions>
            <v-btn color="pink" varipant="text" @click="bettingsnackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="playingsnackbar" class="mt-n12">
        {{ playingText }}

        <template v-slot:actions>
            <v-btn color="pink" variant="text" @click="playingsnackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
    <!-- </v-container> -->
</template>

<script>
import AuthService from "../../auth/views/authService";

import io from "socket.io-client";

export default {
    name: "graphsPage",

    data: () => ({
        pressButton: false,
        snackbar: false,
        errorsnackbar: false,
        bettingsnackbar: false,
        playingsnackbar: false,
        text: "Insufficient Balance",
        playingText: "Bet cannot be below 1.01",
        betText: "Invalid Bet",
        screenSize: "",
        betSession: false,
        defAmt: 10,
        bet_mlt: 1.1,
        playing: false,

        crashPoint: 0,
        counter: 0,
        defcounter: 1.0,
        intervalId: null,
        isCounterRunning: false,
        BalanceCrash: 0,
        itemsToShow: 16,
        checkMyBets: false
    }),

    computed: {
        userDetails() {
            return JSON.parse(AuthService.user);
        },

        User() {
            return this.$store.getters["auth/authGetters"]("userD");
        },
        isLoggedIn() {
            return AuthService.check();
        },
    },

    methods: {
        abbreviateNumber(number) {
            const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

            // what tier? (determines SI symbol)
            const tier = (Math.log10(number) / 3) | 0;

            // if zero, we don't need a suffix
            if (tier == 0) return number;

            // get suffix and determine scale
            const suffix = SI_SYMBOL[tier];
            const scale = Math.pow(10, tier * 3);

            // scale the number
            const scaled = number / scale;

            // format number and add suffix
            return scaled.toFixed(1) + suffix;
        },



        addOneThDefAmt() {
            this.defAmt = 1000;
        },
        addFiveDefAmt() {
            this.defAmt = 500;
        },

        addTwoDefAmt() {
            this.defAmt = 200;
        },

        addOneDefAmt() {
            this.defAmt = 100;
        },

        getColorForNumber(number) {
            const floorNumber = Math.floor(number);
            const hue = floorNumber * 40;

            // Set the saturation and lightness values
            const saturation = 80;
            const lightness = 60;

            const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
            return color;
        },

        sendBet() {
            const userId = JSON.parse(AuthService.user).user_id;
            if (this.defAmt > this.User.balance) {
                this.errorsnackbar = true;
            } else {
                if (this.defAmt === 0 || this.defAmt === "") {
                    this.bettingsnackbar = true;
                } else {
                    if (this.bet_mlt < 1.01) {
                        this.playingsnackbar = true;
                    } else {
                        this.crashPoint = null;
                        const data = {
                            user_id: userId,
                            user_crash: this.bet_mlt,
                            bet_amount: this.defAmt,
                        };


                        this.$store.dispatch("graph/sendBet", data);
                        this.startCounter();
                        // this.$store.dispatch("auth/getUser", {
                        //     phone: JSON.parse(AuthService.user).phone,
                        // });
                    }
                }
            }
        },
        showSnackBar() {
            this.playingsnackbar = true;
        },
        handleButtonClick() {
            if (!this.playing) {
                this.sendBet();
            } else {
                this.showSnackBar();
            }
        },
        addBetAmt() {
            this.defAmt += 10;
        },

        addBetMLT() {
            this.bet_mlt += 0.5;
        },

        reduceBetMLT() {
            this.bet_mlt -= 0.5;
        },
        reduceBetAmt() {
            if (this.defAmt > 0) {
                this.defAmt -= 10;
                if (this.defAmt < 0) {
                    this.defAmt = 0; // Ensure defAmt doesn't go below 0
                }
            } else {
                this.bettingsnackbar = true;
            }
        },

        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 900px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },
        updateCounter() {
            this.counter = Math.floor(Math.random() * 20);
        },
        stopCounter() {
            clearInterval(this.intervalId);
            this.isCounterRunning = false;
            this.playing = false;
            this.pressButton = false
        },
        startCounter() {
            this.pressButton = true
            this.playing = true;
            this.isCounterRunning = true;
            this.intervalId = setInterval(this.updateCounter, 1);
        },

        resetCounter() {
            this.counter = 0;
        },
        formatInput() {
            this.bet_mlt = parseFloat(this.bet_mlt).toFixed(2);
        },

        validateInput(event) {
            const inputText = event.target.value;

            event.target.value = inputText.replace(/[^0-9]/g, "");

            const numericValue = event.target.value;
            if (isNaN(numericValue)) {
                this.defAmt = 10;
            }
        },

        validateBetInput() {
            // Remove non-numeric and non-decimal characters
            this.bet_mlt = this.bet_mlt.replace(/[^0-9.]/g, "");

            // Allow only one decimal place
            const decimalIndex = this.bet_mlt.indexOf(".");
            if (decimalIndex !== -1) {
                this.bet_mlt = this.bet_mlt.slice(0, decimalIndex + 3);
            }
        },
        scrollToBottom() {
            const container = this.$refs.smallChatsCol;
            if (container) {
                container.scrollTop = container.scrollHeight;
            }
        },
        cantBet() {
            this.errorsnackbar = true;
            this.text = "Data Loading... Please Wait"
        },
    },

    created() {
        const socket = io("https://crash.eaglerift.org:3000", {
            autoConnect: true,
            transports: ["websocket"],
            extraHeaders: {
                "Access-Control-Allow-Origin": "*",
            },
        });

        socket.on('connect', () => {
            this.checkMyBets = true
        });

        socket.on('disconnect', () => {

            this.stopCounter();
            this.errorsnackbar = true;
            this.text = "Connection lost. Check your network"
      
        });

        if (AuthService.check()) {
            const ID = JSON.parse(AuthService.user).user_id;

            socket.on(`${ID}_limbo_bet`, (data) => {
            
                this.stopCounter();
                this.crashPoint = `${data.crash_point} x`;
                this.BalanceCrash = data.updatedBalance;
                this.playing = false;
                this.pressButton = false;
            });

            this.checkScreenSize(); // Check screen size initially
            window.addEventListener("resize", this.checkScreenSize);
        }
    }

};
</script>

<style>
@import url("../style.css");

.small-graph-card {
    /* background-image: url("../../../../src/assets/wait.gif"); */
    height: 300px;
    background-color: #0e0e0e;

    background-position: cover;
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.running-small-graph-card {
    /* background-image: url("../../../../src/assets/go.gif"); */
    height: 300px;
    background-color: #0e0e0e;

    background-position: cover;
    background-size: 100% auto;
    background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .small-graph-card {
        /* background-image: url("../../../../src/assets/wait.gif"); */
        height: 250px;
        background-color: #0e0e0e;

        background-position: cover;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }

    .running-small-graph-card {
        /* background-image: url("../../../../src/assets/go.gif"); */
        height: 300px;
        background-color: #0e0e0e;

        background-position: cover;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
}

.gif-image {
    width: 100%;
    height: auto;
}
</style>
