<template>
  <div class="center-horizontally">
    <v-row class="mt-3 mx-1">

      <v-col align="center">
        <v-img
          src="https://img.icons8.com/?size=100&id=18981&format=png&color=000000"
          :width="50"
        />
        <p class="text">Get Coins</p>
      </v-col>
      <v-col align="center">
        <v-img
          src="https://img.icons8.com/?size=100&id=31018&format=png&color=000000"
          :width="50"
        />
        <p class="text">African Zoo</p>
      </v-col>
      <v-col align="center">

     
        <v-img
          src="https://img.icons8.com/?size=100&id=30226&format=png&color=000000"
          :width="50"
        />
        <p class="text"> Coins Spin</p>
      </v-col>

      <v-col align="center">
        <v-img
          src="https://img.icons8.com/?size=100&id=bLyOMn7joRVN&format=png&color=000000"
          :width="50"
        />
        <p class="text">Invite Friends</p>
      </v-col>

      <v-col align="center">
        <v-img
          src="https://img.icons8.com/?size=100&id=15428&format=png&color=000000"
          :width="50"
        />
        <p class="text">Download</p>
      </v-col>

   
    </v-row>

    <v-row class="mt-n1 mx-1">
    <v-col cols="12" sm="12" md="12" lg="12" >
      <v-img :src="require('@/assets/bet.jpeg')" class="main-img" max-width="540" max-height="250" />
    </v-col>
   
  </v-row>
    <v-row no-gutters class="mt-4">
      <v-col cols="12">
        <v-row>
          <v-col>
            <v-col @click="redirectToFair()" class="col-img mx-2"> </v-col>
            <button class="play-button mt-2 ml-2">PLAY NOW</button>
          </v-col>

          <v-col>
            <v-col @click="redirectToLive()" class="col-img2 mx-2"> </v-col>
            <button class="play-button mt-2 ml-2">PLAY NOW</button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-6">
      <v-col cols="12">
        <v-row>
          <v-col>
            <v-col @click="redirectToCoin()" class="col-img3 mx-2"> </v-col>
            <button class="play-button mt-2 ml-2">PLAY NOW</button>
          </v-col>

          <v-col>
            <v-col @click="redirectToRock()" class="col-img4 mx-2"> </v-col>
            <button class="play-button mt-2 ml-2">PLAY NOW</button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-4 mx-1">
    <v-col cols="12" sm="12" md="12" lg="12" >
      <v-img :src="require('@/assets/welcome.jpeg')" class="main-img" max-width="540" max-height="250" />
    </v-col>
   
  </v-row>

    <!-- </div> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "LandingPage",

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },

  data() {
    return {
      screenSize: "",
      showIframe: true,
      open: ["Admin"],
      buttons: [
        {
          label: "Games",
          icon: "mdi-puzzle",
          iconClass: "games-text",
          color: "#fdf4ff",
        },
        {
          label: "Free Bets",
          icon: "mdi-ticket-confirmation",
          iconClass: "bets-text",
          color: "#eff6ff",
        },
        {
          label: "Boosted Odds",
          icon: "mdi-ticket-confirmation",
          iconClass: "boosted-text",
          color: "#f7fee7",
        },
        {
          label: "Player to Score",
          icon: "mdi-tshirt-crew",
          iconClass: "player-text",
          color: "#f5f3ff",
        },
        {
          label: "Vuna Affiliate",
          icon: "mdi-ticket-confirmation",
          iconClass: "vuna-text",
          color: "#fdf4ff",
        },
      ],
    };
  },
  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },

  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    redirectToLive() {
      this.$router.push({ name: "leftSide" });
    },
    redirectToFair() {
      this.$router.push({ name: "fairCrash" });
    },
    redirectToCoin() {
      this.$router.push({ name: "coinFlip" });
    },
    redirectToRock() {
      this.$router.push({ name: "rockPaper" });
    },
  },
};
</script>

<style>
@import url("../style2.css");
</style>
