<template>
  <v-row
    no-gutters
    justify="center"
    class="mt-2 ml-"
    :class="
      screenSize === 'Large' ? 'mt-2 fixed-row ' : 'small-fixed-row  mt-2'
    "
  >

    <v-col class="text-center mt-3" @click="redirectToPage('LandingPage')">
      <v-icon
        :color="
          this.$route.name.includes('LandingPage') && !dialog
            ? '#6104c7'
            : '#9392a0'
        "
        >mdi-home</v-icon
      >
      <br />
      <div
        class="promotion-text"
        :class="
          this.$route.name.includes('LandingPage') && !dialog
            ? 'active-promotion-text'
            : 'promotion-text'
        "
      >
        Home
      </div>
    </v-col>

    <v-col class="text-center mt-3" @click="redirectToPage('depositMoney')">
      <v-icon
        :color="
          this.$route.name.includes('depositMoney') && !dialog
            ? '#6104c7'
            : '#9392a0'
        "
        >mdi-wallet-outline</v-icon
      >
      <br />
      <div
        class="promotion-text"
        :class="
          this.$route.name.includes('depositMoney') && !dialog
            ? 'active-promotion-text'
            : 'promotion-text'
        "
      >
        Wallet
      </div>
    </v-col>
    <v-col class="text-center mt-n9" @click="redirectToPage('promotion')">
      <v-avatar color="#1e1f2d" size="65">
        <v-icon color="#9392a0">mdi-gift</v-icon>
      </v-avatar>

      <br />
      <div class="promotion-text mt-2">Promotion</div>
    </v-col>

    <v-col class="text-center mt-3" @click="redirectToPage('Promotion')">
      <v-icon
        :color="
          this.$route.name.includes('Promotion') && !dialog
            ? '#6104c7'
            : '#9392a0'
        "
        >mdi-cash-multiple</v-icon
      >
      <br />
      <div
        class="promotion-text"
        :class="
          this.$route.name.includes('Promotion') && !dialog
            ? 'active-promotion-text'
            : 'promotion-text'
        "
      >
        withdraw
      </div>
    </v-col>

    <v-col class="text-center mt-3" @click="redirectToPage('profilePage')">
      <v-icon
        :color="
          this.$route.name.includes('profilePage') ? '#6104c7' : '#9392a0'
        "
      >
        mdi-account-circle-outline</v-icon
      >
      <br />
      <div
        class="promotion-text"
        :class="
          this.$route.name.includes('profilePage')
            ? 'active-promotion-text'
            : 'promotion-text'
        "
      >
        Profile
      </div>
    </v-col>
    <!-- <v-col class="text-center mt-3" @click="dialog = true">
      <v-icon :color="dialog ? '#6104c7' : '#9392a0'">mdi-menu</v-icon>
      <br />
      <div
        class="promotion-text"
        :class="dialog ? 'active-promotion-text' : 'promotion-text'"
      >
        Profile
      </div>
    </v-col> -->
  </v-row>
  <div class="text-center pa-4 dialog-page">
    <v-dialog v-model="dialog" class="dialog-Page" fullscreen>
      <v-card variant="flat">
        <div class="appbar-text mt-0 ml-3">AVIATOR</div>

        <v-list class="nav-card">
          <v-list-group class="nav-card" value="Admin">
            <template v-slot:activator="{ props }">
              <v-list-item class="nav-link" v-bind="props">Sports</v-list-item>
            </template>
            <!-- <v-divider vertical></v-divider> -->
            <v-list-item
              class="nav-item"
              v-for="([title, icon], i) in admins"
              :key="i"
            >
              <v-btn flat class="nav-chips text-none mx-3 mt-1 mb-1"
                ><span class="nav-chip-text"
                  ><v-icon rotate="90">{{ icon }}</v-icon> {{ title }}</span
                ></v-btn
              ></v-list-item
            >
          </v-list-group>
        </v-list>
        <v-card-actions class="actions"
          ><v-spacer />
          <div class="signInX mt-n4 mr-4" @click="dialog = false">X</div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "footerPage",

  data() {
    return {
      dialog: false,
      screenSize: "",
      admins: [
        ["My Bets", "mdi-file-document"],
        ["Boosted Odds", "mdi-cog-outline"],
        ["Player to Score", "mdi-cog-outline"],
        ["Sports", "mdi-cog-outline"],
        ["Live Games", "mdi-cog-outline"],
      ],
    };
  },

  computed: {},

  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    redirectToPage(val) {
      this.$router.push({ name: val });
    },
  },

  watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
