<template>
  <v-row class="mt-0" justify="center">
    <v-card color="#1b1c1d" class="crash-points-card mt-3" justify="center" variant="outlined"
      v-if="liveMyBets.length !== 0">
      <v-chip class="chip-color mx-2" size="small" :style="{
        color: getColorForNumber(item.crash_point),
      }" v-for="(item, i) in liveMyBets.slice(0, 5)" :key="i">
        <span class="crash-number" :style="{
          color: getColorForNumber(item.crash_point),
        }">{{ abbreviateNumber(item.crash_point) + "x" }}</span>
      </v-chip></v-card>
    <v-card color="#1b1c1d" class="mx-auto crash-points-card mt-0" justify="center" variant="outlined"
      v-if="liveMyBets.length === 0">
      <span class="text-white">Your bets will appear here</span>
    </v-card>
  </v-row>
 <BustPage />

  <v-row>
    <v-col cols="12" md="12" sm="12" class="small-chat-column">
      <v-card class="mx-auto bets-card" variant="outlined">
        <v-card-item sm="12">
          <div class="btn-div">
            <v-btn
              :class="
                displayItem === 'allBets'
                  ? 'active-bet-btn text-none'
                  : 'text-none bet-btn'
              "
              size="small"
              rounded
              @click="display('allBets')"
              flat
              plain
              variant="outlined"
            >
              <span class="bet-btn-txt">All Bets</span>
            </v-btn>
            <v-btn
              v-if="isLoggedIn"
              :class="
                displayItem === 'myBets'
                  ? 'active-bet-btn text-none'
                  : 'text-none bet-btn'
              "
              size="small"
              rounded
              flat
              @click="display('myBets')"
              plain
              variant="outlined"
            >
              <span class="bet-btn-txt">My Bets</span>
            </v-btn>
            <v-btn
              :class="
                displayItem === 'chats'
                  ? 'active-bet-btn text-none'
                  : 'text-none bet-btn'
              "
              size="small"
              rounded
              flat
              plain
              @click="display('chats')"
              variant="outlined"
            >
              <span class="bet-btn-txt">Chat</span>
            </v-btn>
            <v-btn
              :class="
                displayItem === 'topBets'
                  ? 'active-bet-btn text-none'
                  : 'text-none bet-btn'
              "
              size="small"
              rounded
              flat
              @click="display('topBets')"
              plain
              variant="outlined"
            >
              <span class="bet-btn-txt">Top</span>
            </v-btn>
          </div>

          <!-- All Bets div -->
          <div v-if="displayItem === 'allBets'">
            <v-row><v-col>
                <div class="bets-text-div">
                  <span class="bet-txt">ALL BETS</span>
                  <p class="bet-txt mt-n1">839</p>
                </div>
              </v-col><v-col>
                <div class="right-bets-text-div">
                  <v-btn class="text-none bet-btn mt-2" size="small" rounded density="compact" flat plain
                    variant="outlined">
                    <span class="bet-btn-txt">
                      <v-icon icon="mdi-history" color="green" size="12"></v-icon>Previous hand</span>
                  </v-btn>
                </div>
              </v-col></v-row>
          </div>
        </v-card-item>

        <!-- All Bets div -->

        <v-card-item class="mt-n3 card-item-area" v-if="displayItem === 'allBets'">
          <v-list class="left-first-list-column">
            <v-row class="ml-0"><v-col cols="3">
                <span class="first-col-text ml-3">User</span></v-col>
              <v-col cols="3">
                <span class="first-col-text ml-n5">Amount</span>
              </v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n6">X</span>
              </v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n8">Bust</span></v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n6">Won</span></v-col></v-row>

            <v-list-item v-for="(item, i) in liveBets" :key="i">
              <div :class="item.bet_outcome === 1 ? 'list-row' : ''">
                <v-row class="">
                  <v-col class="small-user-name column-align mt-1" cols="2">
                    <v-avatar size="20px">
                      <v-img alt="Avatar" src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"></v-img>
                    </v-avatar>

                    <span class="small-num-style mt-1">{{
                      item.username
                    }}</span></v-col>
                  <v-col class="small-message-body mt-2" cols="3">
                    <span class="ml-5 mt-1">{{
                      abbreviateNumber(item.bet_amount)
                    }}</span>
                  </v-col>
                  <v-col class="small-message-body mt-2" cols="2">
                    <!-- <v-chip
                            class="chip-color"
                            size="x-small"
                            color="green"
                          > -->
                    <span class="small-crash-number">{{
                      abbreviateNumber(item.user_crash) + "x"
                    }}</span>
                    <!-- </v-chip> -->
                  </v-col>
                  <v-col class="small-message-body mt-2" cols="2">
                    <!-- <v-chip
                            class="chip-color
                            size="x-small"
                            v-if="item.bet_outcome === 1"
                          > -->
                    <span v-if="item.bet_outcome === 1" class="crash-number mt-2" :style="{
                      color: getColorForNumber(item.crash_point),
                    }">{{ abbreviateNumber(item.crash_point) + "x" }}</span>
                    <!-- </v-chip> -->
                  </v-col>

                  <v-col class="small-message-body mt-2 ml-0" cols="3">
                    <span class="ml-5">
                      {{
                        item.win_amount === 0
                          ? "--"
                          : abbreviateNumber(item.win_amount)
                      }}</span>
                  </v-col>
                </v-row>
              </div>
            </v-list-item></v-list>
        </v-card-item>

        <v-card-item class="mt-n3" v-if="displayItem === 'myBets'">
          <v-list class="left-first-list-column">
            <v-row class="ml-0"><v-col cols="3">
                <span class="first-col-text ml-3">User</span></v-col>
              <v-col cols="3">
                <span class="first-col-text ml-n5">Amount</span>
              </v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n6">X</span>
              </v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n8">Bust</span></v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n6">Won</span></v-col></v-row>

            <v-list-item v-for="(item, i) in liveMyBets" :key="i">
              <div :class="item.bet_outcome === 1 ? 'list-row' : ''">
                <v-row class="">
                  <v-col class="small-user-name column-align mt-1" cols="2">
                    <v-avatar size="20px">
                      <v-img alt="Avatar" src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"></v-img>
                    </v-avatar>

                    <span class="small-num-style mt-1">{{
                      item.username
                    }}</span></v-col>
                  <v-col class="small-message-body mt-2" cols="3">
                    <span class="ml-5 mt-1">{{
                      abbreviateNumber(item.bet_amount)
                    }}</span>
                  </v-col>
                  <v-col class="small-message-body mt-2" cols="2">
                    <!-- <v-chip
                            class="chip-color"
                            size="x-small"
                            color="green"
                          > -->
                    <span class="small-crash-number">{{
                      abbreviateNumber(item.user_crash) + "x"
                    }}</span>
                    <!-- </v-chip> -->
                  </v-col>
                  <v-col class="small-message-body mt-2" cols="2">
                    <!-- <v-chip
                            class="chip-color
                            size="x-small"
                            v-if="item.bet_outcome === 1"
                          > -->
                    <span v-if="item.bet_outcome === 1" class="crash-number mt-2" :style="{
                      color: getColorForNumber(item.crash_point),
                    }">{{ abbreviateNumber(item.crash_point) + "x" }}</span>
                    <!-- </v-chip> -->
                  </v-col>

                  <v-col class="small-message-body mt-2 ml-0" cols="3">
                    <span class="ml-5">
                      {{
                        item.win_amount === 0
                          ? "--"
                          : abbreviateNumber(item.win_amount)
                      }}</span>
                  </v-col>
                </v-row>
              </div>
            </v-list-item></v-list>
        </v-card-item>

        <v-card-item class="mt-n3" v-if="displayItem === 'topBets'">
          <v-list class="left-first-list-column">
            <v-row class="ml-0"><v-col cols="3">
                <span class="first-col-text ml-3">User</span></v-col>
              <v-col cols="3">
                <span class="first-col-text ml-n5">Amount</span>
              </v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n6">X</span>
              </v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n8">Bust</span></v-col>
              <v-col cols="2">
                <span class="first-col-text ml-n6">Won</span></v-col></v-row>

            <v-list-item v-for="(item, i) in TopWinningBets" :key="i">
              <div :class="item.bet_outcome === 1 ? 'list-row' : ''">
                <v-row class="">
                  <v-col class="small-user-name column-align mt-1" cols="2">
                    <v-avatar size="20px">
                      <v-img alt="Avatar" src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"></v-img>
                    </v-avatar>

                    <span class="small-num-style mt-1">{{
                      item.username
                    }}</span></v-col>
                  <v-col class="small-message-body mt-2" cols="3">
                    <span class="ml-5 mt-1">{{
                      abbreviateNumber(item.bet_amount)
                    }}</span>
                  </v-col>
                  <v-col class="small-message-body mt-2" cols="2">
                    <!-- <v-chip
                            class="chip-color"
                            size="x-small"
                            color="green"
                          > -->
                    <span class="small-crash-number">{{
                      abbreviateNumber(item.user_crash) + "x"
                    }}</span>
                    <!-- </v-chip> -->
                  </v-col>
                  <v-col class="small-message-body mt-2" cols="2">
                    <!-- <v-chip
                            class="chip-color
                            size="x-small"
                            v-if="item.bet_outcome === 1"
                          > -->
                    <span v-if="item.bet_outcome === 1" class="crash-number mt-2" :style="{
                      color: getColorForNumber(item.crash_point),
                    }">{{ abbreviateNumber(item.crash_point) + "x" }}</span>
                    <!-- </v-chip> -->
                  </v-col>

                  <v-col class="small-message-body mt-2 ml-0" cols="3">
                    <span class="ml-5">
                      {{
                        item.win_amount === 0
                          ? "--"
                          : abbreviateNumber(item.win_amount)
                      }}</span>
                  </v-col>
                </v-row>
              </div>
            </v-list-item></v-list>
        </v-card-item>

        <!-- All Chats div -->
        <v-card-item v-if="displayItem === 'chats'">
          <v-list class="left-first-chat-list-column" ref="smallChatsCol" id="second-chats-col">
            <v-row><v-col></v-col>
              <v-col><v-icon icon="mdi-checkbox-blank-circle" color="green" size="8"></v-icon>
                <span class="online-text">3032</span>
              </v-col>
              <v-col></v-col></v-row>

            <v-list-item v-for="(item, i) in liveChats" :key="i">
              <v-row>
                <v-col class="user-name column-align" md="3">
                  <v-avatar size="20px">
                    <v-img alt="Avatar" src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"></v-img>
                  </v-avatar>

                  <span class="small-num-style">{{
                    item.username
                  }}</span></v-col>
                <v-col class="small-message-body" md="7">
                  {{ item.message }}</v-col>
                <v-col class="time-name">
                  <v-icon icon="mdi-thumb-up-outline" size="x-small"></v-icon>
                </v-col>
              </v-row> </v-list-item></v-list>
          <v-divider class="divider" />
          <v-row v-if="isLoggedIn">
            <v-col cols="9">
              <v-text-field label="Reply" outlined v-model="ChatMessage" class="custom-label-color" placeholder="Reply"
                variant="plain"></v-text-field></v-col>
            <v-col cols="3" class="mt-3">
              <v-icon @click="sendMessage()" color="white">mdi-send</v-icon></v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-col>
  </v-row>

  <v-snackbar v-model="errorsnackbar" class="mt-n12">
    {{ text }}

    <template v-slot:actions>
      <v-btn color="pink" variant="text" @click="errorsnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>

  <v-snackbar v-model="bettingsnackbar" class="mt-n12">
    {{ betText }}

    <template v-slot:actions>
      <v-btn color="pink" variant="text" @click="bettingsnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar v-model="playingsnackbar" class="mt-n12">
    {{ playingText }}

    <template v-slot:actions>
      <v-btn color="pink" variant="text" @click="playingsnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- </v-container> -->
</template>

<script>


import AuthService from "../../auth/views/authService";

import io from "socket.io-client";
import BustPage from "./bustPage.vue";

export default {
  name: "graphsPage",
  components:{BustPage},



  data: () => ({
    snackbar: false,
    errorsnackbar: false,
    bettingsnackbar: false,
    playingsnackbar: false,
    text: "Insufficient Balance",
    playingText: "Bet cannot be below 1.01",
    betText: "Invalid Bet",
    screenSize: "",
    ChatMessage: "",
    betSession: false,

    defAmt: 10,

  
   
    bet_mlt: 1.1,
    liveBets: [],
    liveChats: [],
    TopWinningBets: [],
    liveMyBets: [],
    playing: false,

   

    isRunning: false,
    timer: null,
    liveMultiplierSwitch: true,
    crashPoint: 0,
    counter: 0,
    defcounter: 1.0,
    intervalId: null,
    isCounterRunning: false,
  
    BalanceCrash: 0,
    itemsToShow: 16,
    displayItem: "allBets",

  }),

  computed: {
    userDetails() {
      return JSON.parse(AuthService.user);
    },

    User() {
      return this.$store.getters["auth/authGetters"]("userD");
    },
    isLoggedIn() {
      return AuthService.check();
    },
    alertStatus() {
      return this.$store.getters["graph/graphGetters"]("alert");
    },
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("alert");
    },
  
    betLive() {
    return !(
      this.liveBets.length === 0 || 
      this.liveMyBets.length === 0 || 
      this.liveChats.length === 0 || 
      this.TopWinningBets.length === 0
    );
  }
  },

  methods: {
    abbreviateNumber(number) {
      const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(number) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) return number;

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
    display(val) {
      this.displayItem = val;
    },

    addOneThDefAmt() {
      this.defAmt = 1000;
    },
    addFiveDefAmt() {
      this.defAmt = 500;
    },

    addTwoDefAmt() {
      this.defAmt = 200;
    },

    addOneDefAmt() {
      this.defAmt = 100;
    },

    getColorForNumber(number) {
      const floorNumber = Math.floor(number);
      const hue = floorNumber * 40;

      // Set the saturation and lightness values
      const saturation = 80;
      const lightness = 60;

      const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      return color;
    },

    sendBet() {
      if (this.defAmt > this.User.balance) {
        this.errorsnackbar = true;
      } else {
        if (this.defAmt === 0 || this.defAmt === "") {
          this.bettingsnackbar = true;
        } else {
          if (this.bet_mlt < 1.01) {
            this.playingsnackbar = true;
          } else {
            this.playing = true;
            const data = {
              user_id: this.userDetails.user_id,
              user_crash: this.bet_mlt,
              bet_amount: this.defAmt,
            };
            this.crashPoint = "";
           
            this.$store.dispatch("graph/sendBet", data);
            this.startCounter();
            this.$store.dispatch("auth/getUser", {
              phone: JSON.parse(AuthService.user).phone,
            });
    
          }
        }
      }
    },
    showSnackBar() {
      this.playingsnackbar = true;
    },
    handleButtonClick() {
      if (this.playing === false) {
        this.sendBet();
      } else {
        this.showSnackBar();
      }
    },
    addBetAmt() {
      this.defAmt += 10;
    },

    addBetMLT() {
      this.bet_mlt += 0.5;
    },

    reduceBetMLT() {
      this.bet_mlt -= 0.5;
    },
    reduceBetAmt() {
      if (this.defAmt > 0) {
        this.defAmt -= 10;
        if (this.defAmt < 0) {
          this.defAmt = 0; // Ensure defAmt doesn't go below 0
        }
      } else {
        this.bettingsnackbar = true;
      }
    },

    logOut() {
      AuthService.logout();
    },

    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 900px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    updateCounter() {
      this.counter = Math.floor(Math.random() * 20);
    },
    stopCounter() {
      clearInterval(this.intervalId);
      this.isCounterRunning = false;
      this.playing = false;
    },
    startCounter() {
      if (!this.isCounterRunning) {
        // alert('noma');
        this.playing = true;
        this.isCounterRunning = true;
        this.intervalId = setInterval(this.updateCounter, 1);
        setTimeout(this.stopCounter, 12000);
      }
    },

    resetCounter() {
      this.counter = 0;
    },
    formatInput() {
      this.bet_mlt = parseFloat(this.bet_mlt).toFixed(2);
    },

    sendMessage() {
      if (this.ChatMessage !== "") {
        const data = {
          message: this.ChatMessage,
          user_id: this.userDetails.user_id,
        };
        this.$store.dispatch("graph/sendMessage", data);
        this.$store.dispatch("graph/getAllChats");
        this.ChatMessage = "";
      }
    },

    Login() {
      if (!this.isValid) {
        this.$refs.loginForm.validate();
      } else {
        this.$store.dispatch("auth/login", this.formData);
      }
    },
    Register() {
      if (!this.isValid) {
        this.$refs.registerFormForm.validate();
      } else {
        this.$store.dispatch("auth/register", this.regformData);
      }
    },

   




 
    validateInput(event) {
      const inputText = event.target.value;

      event.target.value = inputText.replace(/[^0-9]/g, "");

      const numericValue = event.target.value;
      if (isNaN(numericValue)) {
        this.defAmt = 10;
      }
    },

    validateBetInput() {
      // Remove non-numeric and non-decimal characters
      this.bet_mlt = this.bet_mlt.replace(/[^0-9.]/g, "");

      // Allow only one decimal place
      const decimalIndex = this.bet_mlt.indexOf(".");
      if (decimalIndex !== -1) {
        this.bet_mlt = this.bet_mlt.slice(0, decimalIndex + 3);
      }
    },
    scrollToBottom() {
      const container = this.$refs.smallChatsCol;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
 
    fetchData() {
      if (this.liveBets.length === 0) {
        this.$store.dispatch("graph/getAllBets");
      }
      if (this.liveMyBets.length === 0 && AuthService.check()) {
        this.$store.dispatch("graph/getMyBets", {
          user_id: JSON.parse(AuthService.user).user_id,
        });
      }
      if (this.liveChats.length === 0) {
        this.$store.dispatch("graph/getAllChats");
      }
      if (this.TopWinningBets.length === 0) {
        this.$store.dispatch("graph/getTopBets");
      }
    },
    startFetchingData() {
      this.intervalId = setInterval(this.fetchData, 2000); // Fetch data every 5 seconds
    },
    stopFetchingData() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
  },

  created() {
    const socket = io("https://crash.eaglerift.org:3000", {
      autoConnect: true,
      transports: ["websocket"],
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    socket.on("plane_chat_all", (data) => {
      const chats = data.chatRecordsWithUserDetails;
      this.liveChats = chats;
      // alert('noma sana')
    });
    if (AuthService.check()) {
      const ID = JSON.parse(AuthService.user).user_id;

      socket.on(`${ID}_limbo_bet`, (data) => {
        this.crashPoint = data.crash_point;
        this.BalanceCrash = data.updatedBalance;
        this.playing = false;
        this.stopCounter();
      });

      socket.on(`${ID}_limbo_my_bets`, (data) => {
        this.liveMyBets = data.betRecordsWithUserDetails;
      });
    }

    socket.on("limbo_top_bets", (data) => {
      this.TopWinningBets = data.topBets;
    });

    socket.on("limbo_bet_all", (data) => {
      this.liveBets = data.betRecordsWithUserDetails;
    });

    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  mounted() {
    if (!this.betLive) {
      this.startFetchingData();
    } else {
      this.stopFetchingData();
    }
  },





  watch: {
    // liveBets: {
    //   handler: function () {
    //     if (this.liveBets.length === 0) {
    //       this.startFetchingData();
    //     } else {
    //       this.stopFetchingData();
    //     }
    //   },
    //   // deep: true,
    //   immediate: true, // To ensure the watcher triggers immediately
    // },
    // liveMyBets: {
    //   handler: function () {
    //     if (this.liveMyBets.length === 0 && AuthService.check()) {
    //       this.startFetchingData();
    //     } else {
    //       this.stopFetchingData();
    //     }
    //   },
    //   // deep: true,
    //   immediate: true,
    // },
    // liveChats: {
    //   handler: function () {
    //     if (this.liveChats.length === 0) {
    //       this.startFetchingData();
    //     } else {
    //       this.stopFetchingData();
    //     }
    //   },
    //   // deep: true,
    //   immediate: true,
    // },
    // TopWinningBets: {
    //   handler: function () {
    //     if (this.TopWinningBets.length === 0) {
    //       this.startFetchingData();
    //     } else {
    //       this.stopFetchingData();
    //     }
    //   },
    //   // deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style>
@import url("../style.css");
</style>
